
// @media only screen and (min-width: 500px), (min-height: 951px) {
//   body {
//     max-width: 499px;
//     max-height: 950px;
//     margin: 0 auto;
//     overflow: scroll;
//     height: 100vh;
//   }
// }
.background{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  max-width: 499px;

  .img{
    width: 100%;
    height: 60vh;
    object-fit: cover;
  }
  .img-gradation{
    width: 100%;
    height: 3vh;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.9);
  }
  .logo{
    font-size: 16px;
    font-weight: 700;
    color: #2C211A;
    padding: 2vh 0 0 0;
  }
  .ment{
    font-size: 24px;
    font-weight: 800;
    color: #262626;
    margin: 0 0 6vh 0;
  }
  .modal-inner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    text-align: start;
    width: 90%;
    
    .symbol{
      width: 7vh;
      height: 7vh;

    }
    .modal-title{
      font-size: 3vh;
      font-weight: 700;
      margin: 2vh 0 1vh 0;
    }
    .modal-description{
      font-size: 1.7vh;
      font-weight: 500;
      margin: 0 0 3vh 0;
      color: #6F6F6F;
      font-family: 'Pretendard';
    }
    .modal-button-area{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}

